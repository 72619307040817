import "./UserContainer.css";
import userImg from "../../../../../../assets/img/user.svg";
import { useSelector } from "react-redux";

const UserContainer = () => {
    const username = useSelector((state) => state.auth.username.payload) ?? localStorage.getItem("username");
    
    return (
        <div className="user-container">
            <img src={userImg} alt="user" />
            <span>{username}</span>
        </div>
    );
};

export default UserContainer;